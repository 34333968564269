import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import DatePicker from 'v-calendar/src/components/DatePicker.vue';
import DateHelper from '@prd/shared-core/dist/helpers/DateHelper';
import { DateFormat } from '@prd/shared-core/dist/helpers/DateFormat';
import { TimeFormat } from '@prd/shared-core/dist/helpers/TimeFormat';

@Component({
    components: {
        DatePicker,
    },
})
export default class DateTimePickerComponent extends Vue {
    @Prop() public value: string;
    @Prop({ type: Boolean }) public outputDate: boolean;

    public get date(): Date | null {
        if (this.value) {
            return new Date(this.value);
        }
        return null;
    }

    public set date(value: Date | null) {
        if (value) {
            this.$emit('input', this.outputDate ? value : new DateHelper(value).iso());
        } else {
            this.$emit('input', null);
        }
    }

    public get formatted(): string {
        return this.date ? new DateHelper(this.date).dateString(DateFormat.ShortDate) : '';
    }

    public get formattedTime(): string {
        return this.date ? new DateHelper(this.date).timeString(TimeFormat.ShortTime) : '';
    }
}
